@use '../../styles/mixins' as mixins;


.related-insights-block {

	@include mixins.sectionStyles;
	
	.info {
		display: flex;
		gap: var(--wp--preset--spacing--40);
		flex-wrap: wrap;
		align-items: flex-end;
		justify-content: space-between;
	}

	&:has(.posts-grid[data-post-count="0"]) {
		display: none;
	}
	
}